.mt-15[data-v-38d69136] {
  margin-top: 15px !important;
}
.top_p[data-v-38d69136] {
  font-size: 14px;
  color: red;
}
.top_steps[data-v-38d69136] {
  margin: 0 auto;
  margin-bottom: 15px;
  font-size: 13px;
}
.top_steps_import[data-v-38d69136] {
  text-align: center;
  margin: 10px 0;
}
.mt-62[data-v-38d69136] {
  margin-top: 62px;
}
.empty-div[data-v-38d69136] {
  position: absolute;
  top: 20px;
  left: 20px;
}
.el-radio-button--medium .el-radio-button__inner[data-v-38d69136] {
  padding: 10px;
  width: 140px;
  border-radius: 0px;
}
.exchange-direct[data-v-38d69136] {
  color: red;
  font-size: 15px;
}
.footer-container[data-v-38d69136] {
  width: 100%;
  margin-top: 16px;
}
.footer-container .balance_box[data-v-38d69136] {
  float: left;
  width: 50%;
}
.footer-container .balance_box h6[data-v-38d69136] {
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
}
.footer-container .balance_box .balance_font p[data-v-38d69136] {
  font-size: 13px;
  color: #333;
  line-height: 18px;
}
.footer-container .right_btns[data-v-38d69136] {
  width: 20%;
  float: right;
  text-align: right;
}
.import_box[data-v-38d69136] {
  position: relative;
  margin-top: 40px;
}
.excel-upload-input[data-v-38d69136] {
  display: none;
  z-index: -9999;
}
.drop[data-v-38d69136] {
  border: 2px dashed #bbb;
  width: 701px;
  height: 160px;
  margin: 0 auto;
  font-size: 13px;
  font-weight: 500;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
  cursor: pointer;
}
.drop .upload-filled[data-v-38d69136] {
  font-size: 30px;
  margin-top: 40px;
  color: #bbbbbb;
}
.next-step[data-v-38d69136] {
  margin-top: 30px;
  float: right;
}
.reset-btn[data-v-38d69136] {
  margin-top: 30px;
  float: right;
  margin-right: 10px;
}
.second-table-box[data-v-38d69136] {
  width: 100%;
  margin-top: 10px;
}
.drop2[data-v-38d69136] {
  border: 2px dashed #bbb;
  width: 701px;
  height: 160px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
.tabs[data-v-38d69136] {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgb(229, 229, 229);
}
.tabs img[data-v-38d69136] {
  height: 60px;
}
.tab_content[data-v-38d69136] {
  width: 150px;
  text-align: center;
  cursor: pointer;
  padding-bottom: 5px;
}
.tab_content.active[data-v-38d69136] {
  color: #17a2b8;
  border-bottom: 1px solid #17a2b8;
}
.tab_title[data-v-38d69136] {
  font-size: 13px;
}
.tab_flex[data-v-38d69136] {
  display: flex;
  align-items: center;
}
.triangle-container[data-v-38d69136] {
  margin: 0 30px;
}
.triangle-container img[data-v-38d69136] {
  width: 26px;
  height: auto;
}